.sponsorsTd a {
  color: #2BAF49;
  text-decoration: none;
}

.sponsorsTd a:hover {
  text-decoration: underline;
}

.sponsorTable {
  position: relative;
  z-index: 1;
}