@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.img {
  max-width: 400px;
  max-height: 400px;
}

.topics_nav {
  position: fixed;
  margin-top: -44px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 500;
}

.topics_nav_toggle {
  display: none;
  cursor: pointer;
  margin-top: 7px;
}

.topics_nav_toggle span {
  display: block;
  height: 2px;
  width: 25px;
  margin-bottom: 5px;
  background: #555;
  position: relative;
}

.topics_nav_toggle span:nth-child(2) {
  width: 20px;
}

.topics_nav_toggle span:last-child {
  margin-bottom: 0;
}

.topics_navbar_menu {
  padding-left: 200px;
  position: absolute;
  margin-top: 160px;
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  overflow-x: auto;
}

.topics_navbar_menu ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
}

.topics_navbar_menu::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.topics_navbar_menu a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #e0dfdf;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

.topics_navbar_menu li :hover {
  background-color: #2BAF49;
}

.home_container {
  margin-top: 100px;
  background: #f1f1f1;
  color: #333;
  padding: 2rem;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home_title {
  font-size: 2.5rem;
  margin-bottom: 0.5em;
  color: #383E42;
  font-family: 'Arial', sans-serif;
}

.home_intro {
  font-size: 1.5rem;
  line-height: 2.5;
  color: #555;
  font-family: 'Georgia', serif;
  animation: blinkCursor 0.75s step-end infinite;
}

.home_subtitle {
  font-size: 2rem;
  color: #333;
  margin-top: 2em;
  font-family: 'Helvetica', sans-serif;
}

.home_info,
.home_invite {
  font-size: 1rem;
  margin-top: 1em;
  color: #666;
  font-family: 'Courier New', monospace;
}

.topics_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.topic_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #d8d4d4, #8a8f91);
  color: white;
  padding: 1rem;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.2rem;
  width: 400px;
  height: 200px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: transform 0.5s, font-size 0.5s;
  cursor: pointer;
}

.topic_button h3 {
  color: white;
  margin-top: 1rem;
  text-align: center;
  font-size: 1.5rem;
  transition: font-size 0.5s;
}

.topic_button:hover h3 {
  font-size: 2.9rem;
}

.home_container {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 0 15px;
  background: #f5f5f5;
  color: #333;
}

.home_title {
  font-size: 2.5em;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 30px;
}

.home_info {
  font-size: 1.8em;
  background: white;
  padding: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 auto;
  max-width: 800px;
}

.home_invite {
  font-size: 1.5em;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 60px;
}

.home_subtitle {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 20px;
}

.topics_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.topic_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 180px;
  margin: 15px;
  background: linear-gradient(to bottom right, #383E42, #2BAF49);
  color: #dfdfdf;
  text-decoration: none;
  border-radius: 10px;
  font-size: 25px;
  transition: all 0.3s ease-in-out, background 0.5s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.topic_button:hover {
  transform: scale(1.1);
  background: linear-gradient(to top left, #2BAF49, #E8DE63);
  transition: transform 0.3s ease-in-out, background 0.5s ease;
}

.social_links_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.social_link {
  margin: 0 15px;
  transition: transform 0.2s;
}

.social_link:hover {
  transform: scale(1.1);
}

.social_icon {
  width: 30px;
  height: 30px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.donationPopup {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 800;
  border-radius: 5px;
  max-width: 400px;
  text-align: center;
  position: relative;
  color: #333;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1rem;
  background: none;
  border: none;
  color: #333;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.problemListContainer {
  margin-top: 150px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.filterInput {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.problemTable {
  margin-top: 20px;
  background-color: #fff;
}

.problemTable th {
  background-color: #f0f0f0;
}

.pagination {
  margin-top: 20px;
  justify-content: center;
}

.modalHeader {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.modalTitle {
  margin: 0;
  font-size: 24px;
}

.paginationContainer {
  display: flex;
  justify-content: center;
}

.fadeIn {
  animation: fadeInEffect 8s ease-out forwards;
}

@keyframes fadeInEffect {
  from {
    opacity: -1;
  }

  to {
    opacity: 1;
  }
}

.topicActive {
  background-color: #2BAF49;
}

.problemListContainer {
  padding: 20px;
}

.header h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.header p {
  font-size: 1.2rem;
  color: #555;
}

.filterContainer {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.filterItem {
  display: flex;
  flex-direction: column;
}

.filterInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.problemTable {
  margin-top: 20px;
}

.explanatoryText {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
}

.explanatoryText h2 {
  color: #333;
}

.explanatoryText ul {
  list-style: square;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .home_title {
    font-size: 2rem;
  }

  .section_intro {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .home_title {
    font-size: 1.5rem;
  }

  .section_intro {
    font-size: 0.5rem;
  }
}