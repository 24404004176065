.footerContainer {
  background-color: #333;
  padding-left: 20px;
  transition: margin-left 0.3s ease;
  color: white;
}

.footerContainer.fullWidth {
  margin-left: 0;
}

.footerTitle {
  color: #2BAF49;
  text-align: center;
}

.footerText {
  color: white;
  text-align: left;
}

.footerContact .footerTitle,
.footerContact .footerText {
  text-align: center;
}

.footerOwnerInfo {
  text-align: center;
}

.footerOwnerInfo p {
  text-align: center;
}

@media (min-width: 1300px) {

  .footerContainer.fullWidth {
    margin-left: 0;
  }
}