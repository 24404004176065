.lessons_menu,
.lessons_menu * {
    font-family: Arial, sans-serif !important;
}

.lessons_menu::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.lessons_menu::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.lessons_menu {
    position: static;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.tree {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    color: #444;
}

.tree ul {
    margin: 0;
    padding: 0;
    display: none;
}

.tree .active>ul {
    display: block;
}

.tree li {
    list-style-type: none;
    line-height: 1.5;
    font-weight: normal;
    padding: 5px 10px;
}

.tree a {
    display: block;
    text-decoration: none;
    color: inherit;
    line-height: 1.5;
    font-size: 14px;
    font-weight: normal;
    padding-left: 15px;
}

.chapters_content {
    display: flex;
    color: white;
}

.chapter {
    margin: 0;
    padding: 12px;
    background-color: #f1f1f1;
    color: #444;
    font-weight: bold !important;
    cursor: pointer;
    width: 100%;
}

.chapter:hover {
    background-color: #ddd;
}

.chapter.active {
    background-color: #ddd;
    font-weight: bold !important;
}

.activeLesson {
    background-color: #2baf49;
    color: white;
}

@media (max-width: 1300px) {
    .lessons_menu { 
        position: fixed;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #f1f1f1;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
        overflow-y: auto;
    }

    .lessons_menu.open {
        transform: translateY(0);
    }


    .chapter {
        padding-bottom: 5px;
        font-size: 16px;
        font-weight: bold !important;
    }

    .mobileHeader {
        display: none;
        width: 100%;
        background-color: #a09e9e;
        color: white;
        position: fixed;
        top: 150px;
        left: 0;
        z-index: 500;
    }

    .mobileMenuButton {
        cursor: pointer;
        display: inline-block;
    }

    @media (max-width: 1300px) {
        .mobileHeader {
            display: block;
        }

        .lessons_menu { 
            position: fixed;
            top:176px;
            left: 0;
            width: 100vw;
            height: 100vh;
            text-align: center;
            background-color: #f1f1f1;
            transform: translateY(-100%);
            transition: transform 0.3s ease-in-out;
            z-index: 1;
            overflow-y: auto;

        }

        .lessons_menu.open {
            transform: translateY(0);
        }
    }

}