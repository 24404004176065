* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}



.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f9f9f9;
  color: #333;
}

.document-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.main-title {
  font-weight: bold;
  font-size: 2.5em;
  color: #2c3e50;
  margin-bottom: 20px;
}

.subtitle {
  font-weight: bold;
  font-size: 1.8em;
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.paragraph {
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 20px;
}

.image-container {
  text-align: center;
  margin: 20px 0;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.code-inline {
  background: #2d2d2d;
  color: #ffffff;
  font-family: "Courier New", Courier, monospace;
  font-size: 0.95em;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px;
  box-sizing: border-box;
  white-space: pre;
  display: block;
  max-width: 100%;
  overflow-x: auto;
  cursor: grab;
  scrollbar-width: thin;
  scrollbar-color: #888 #2d2d2d;
}

.code-inline::-webkit-scrollbar {
  height: 8px;
}

.code-inline::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.code-inline::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.code-inline:active {
  cursor: grabbing;
}

.ide-style {
  border-left: 5px solid #4CAF50;
}

.console-style {
  border-left: 5px solid #FFC107;
}

.unordered-list,
.ordered-list {
  margin: 20px 0;
  padding-left: 40px;
}

.unordered-list li,
.ordered-list li {
  margin-bottom: 10px;
}

@media (max-width: 375px) {
  .main-title {
    font-size: 1.8em;
  }

  .subtitle {
    font-size: 1.3em;
  }

  .paragraph {
    font-size: 0.9em;
    line-height: 1.6;
  }
}

@media (max-width: 768px) {
  .code-inline {
    font-size: 0.85em;
    padding: 8px;
    max-width: 90vw;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

@media (max-width: 375px) {
  .code-inline {
    font-size: 0.75em;
    padding: 6px;
    max-width: 85vw;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}