@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');


body {
  font-family: 'Open Sans', sans-serif;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  color: #383E42;
}

.home_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.section_intro{
  font-size: 1.5rem;
}

.hero_section {
  margin-top: 200px;
  background-color: #363836;
  color: white;
  padding: 60px 20px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 40px;
}

.home_title {
  font-size: 4rem;
  margin: 0;
}

.home_intro {
  margin-top: 20px;
  font-size: 1.7rem;
}

.featured_section {
  margin: 40px 0;
  text-align: center;
}

.home_subtitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #8b8d8b;
}

.topics_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.topic_button {
  background-color: #244924;
  color: white;
  padding: 50px 70px;
  margin: 10px;
  border: 3px solid #000000;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.5rem;
  overflow: hidden;
  position: relative;
  flex: 1 1 300px;
  text-align: center;
  transition: transform 0.5s ease;
}

.topic_button::before {
  content: '';
  background-color: #9eb9a4;
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: left 0.5s ease;
  z-index: 0;
}

.topic_button:hover::before {
  left: 0;
}

.topic_button span {
  position: relative;
  z-index: 1;
}

.topic_button:hover {
  transform: scale(1.05);
}

.about_section,
.invite_section {
  background-color: white;
  padding: 40px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about_section h3,
.invite_section h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2BAF49;
}

.home_info,
.home_invite {
  font-size: 2rem;
  line-height: 1.8;
}

footer,
.social_links {
  text-align: center;
  margin: 20px 0;
}

body {
  font-family: 'Open Sans', sans-serif;
}

.topic_card {
  width: 100%;
  height: 150px;
  padding: 5px;
  margin-bottom: 15px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease, background-color 0.3s ease;
  font-family: 'Open Sans', sans-serif;
}

.topic_card.yellow {
  background-color: #f4e04d; 
}

.topic_card.light_green {
  background-color: #c5e063;
}

.topic_card.medium_green {
  background-color: #9fd86f;
}

.topic_card.green {
  background-color: #76c98f;
}

.topic_card.dark_green {
  background-color: #4db380; 
}


.topic_card:hover {
  transform: scale(1.02);
}

.topic_card.yellow::before,
.topic_card.light_green::before,
.topic_card.medium_green::before,
.topic_card.green::before,
.topic_card.dark_green::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  transition: opacity 0.4s ease;
  opacity: 0;
}

.topic_card:hover::before {
  opacity: 1;
}


.topic_card_content {
  position: relative;
  z-index: 2;
}

.topic_title {
  font-size: 2.8rem;
  font-weight: 600;
  color: #096418;
  margin-bottom: 8px;
}

.topic_description {
  font-size: 1.5rem;
  color: #706d6d;
  margin: 0;
}

.news_slider {
  margin: 2rem 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.slider_item {
  position: relative;
  width: 100%;
  height: 400px; 
}

.slider_background {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.slider_text {
  z-index: 2;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem 2rem;
  border-radius: 10px;
}

.slider_text h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.slider_text p {
  font-size: 1.2rem;
}

.slider_item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}


@media (max-width: 768px) {
  .topic_card {
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
  }

  .topic_title {
    font-size: 2rem;
    margin-bottom: 5px;
  }

  .topic_description {
    font-size: 1rem;
  }

  .news_slider {
    margin: 1.5rem 0;
  }

  .slider_item {
    height: 300px;
  }

  .slider_text h2 {
    font-size: 2rem;
  }

  .slider_text p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .topic_title {
    font-size: 1.8rem;
  }

  .topic_description {
    font-size: 0.9rem;
  }

  .slider_item {
    height: 200px;
  }

  .slider_text h2 {
    font-size: 1.8rem;
  }

  .slider_text p {
    font-size: 0.9rem;
  }

  .slider_text {
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 768px) {
  .home_title {
    font-size: 2rem;
  }

  .section_intro{
    font-size: 1.5rem;
  }

  .home_intro {
    margin-top: 20px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .home_title {
    font-size: 1.5rem;
  }

  .section_intro{
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .news_slider {
    margin: 1rem 0;
  }

  .slider_item {
    height: 250px;
  }

  .slider_text h2 {
    font-size: 1.8rem;
  }

  .slider_text p {
    font-size: 1rem;
  }

  .slider_text {
    padding: 0.8rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .news_slider {
    margin: 0.5rem 0;
  }

  .slider_item {
    height: 280px;
  }

  .slider_text h2 {
    font-size: 1rem;
  }

  .slider_text p {
    font-size: 0.8rem;
  }

  .slider_text {
    padding: 1rem 0.5rem;
  }
}



